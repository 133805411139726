.wrapper {
  padding: 60px 0;

  @media (max-width: 768px) {
    padding-bottom: 20px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .left, .right {
      width: calc(50% - 30px);

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .right {

      h1 {
        font-family: Montserrat, sans-serif;
        font-size: 36px;
        line-height: 33px;
        letter-spacing: 2px;
        font-weight: 900;
        color: var(--text-gray);
        text-transform: uppercase;
        margin-bottom: 20px;

        @media (max-width: 768px) {
          text-align: center;
          margin-top: 30px;
          font-size: 24px;
          line-height: 24px;
        }
      }

      .descriptionContainer {


        p, li {
          font-family: Inter, sans-serif;
          font-size: 16px;
          line-height: 20px;
          color: var(--text-brown);
          font-weight: 300;
          margin: 10px 0;
        }

        ul {
          padding-left: 20px;
        }

        li {
          margin: 5px 0;
          list-style-type: circle;
        }
      }
    }
  }
}
