.wrapper {
  padding: 80px;
  background: white;

  @media (max-width: 768px) {
    padding: 60px 0;
  }

  .titleContainer {

    h2 {
      font-family: Montserrat, sans-serif;
      font-size: 46px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 2px;
      margin: 15px 0;
      color: var(--text-grey2);

      @media (max-width: 768px) {
        font-size: 40px;
      }
    }

    span {
      display: block;
      font-family: Montserrat, sans-serif;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 4px;
      color: var(--text-grey2);

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .groupsContainer {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 50px 0;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .group {

      width: calc(33.3333% - 30px);
      margin: 0 15px;

      &.group2 {
        margin: 0 60px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      h3 {
        font-family: Montserrat, sans-serif;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 2px;
        margin: 15px 0;
        color: var(--text-grey2);
      }

      .itemsContainer {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: calc(50% - 10px);
          background: var(--light-background2);
          margin: 10px 0;
          padding: 15px;
          aspect-ratio: 1 / 1;
          border-radius: 30px;

          .imageWrapper {
            position: relative;

            width: 60px;
            height: 60px;
            margin-bottom: 10px;

          }

          h4 {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            color: var(--text-brown-dark);
          }
        }
      }
    }
  }

  .descriptionContainer {

    h3 {
      font-family: Inter, sans-serif;
      font-size: 20px;
      line-height: 20px;
      color: var(--text-grey2);
      font-weight: 700;
      margin: 20px 0;
    }

    p {
      font-family: Inter, sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: var(--text-grey2);
      margin: 20px 0;
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .descriptionTitle {
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: var(--text-grey2);
    margin: 40px 0;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .whyDescriptionItemsContainer {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .imageWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 120px;
        height: 120px;
        margin-right: 20px;
        background: var(--light-background2);
        border-radius: 20px;

        @media (max-width: 768px) {
          margin-right: 0;
        }

        .imageInnerWrapper {
          position: relative;
          width: 80px;
          height: 80px;
        }
      }

      .content {

        width: calc(100% - 120px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 768px) {
            width: 100%;
        }

        h4 {
          font-family: Inter, sans-serif;
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
          margin-top: 0;
          color: var(--text-grey2);

          @media (max-width: 768px) {
            text-align: center;
            margin-top: 20px;
          }
        }

        p {
          font-family: Inter, sans-serif;
          font-size: 16px;
          line-height: 20px;
          color: var(--text-grey2);
          margin: 10px 0;

          @media (max-width: 768px) {
            text-align: center;
          }
        }
      }
    }
  }
}
