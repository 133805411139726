.wrapper {
  min-height: calc(100vh - 76px);
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    min-height: unset;
  }

  .imageWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .imageWrapperMobile {
    position: relative;
    width: calc(100% + 30px);
    height: 0;
    padding-top: calc(100% + 30px);
    margin-right: -15px;
    margin-left: -15px;
    margin-top: -100px;

    img {
      z-index: -1;
    }
  }

  .contentWrapper {

    display: flex;
    align-items: center;
    justify-content: flex-end;

    .contentContainer {
      width: 50%;
      padding: 40px;

      @media (max-width: 768px) {
        width: 100%;
        padding: 40px 0;
      }

      h2 {
        font-family: Montserrat, sans-serif;
        font-size: 36px;
        line-height: 43px;
        letter-spacing: 4px;
        text-transform: uppercase;
        font-weight: 700;
        color: var(--text-grey2);
        margin-bottom: 40px;

        @media (max-width: 768px) {
          text-align: center;
          font-size: 20px;
          line-height: 30px;
        }
      }

      h3 {
        font-family: Inter, sans-serif;
        font-size: 20px;
        line-height: 24px;
        color: var(--text-grey2);
        font-weight: 700;

        @media (max-width: 768px) {
          text-align: center;
        }
      }

      p {
        font-family: Inter, sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: var(--text-grey2);
        margin-bottom: 25px;

        @media (max-width: 768px) {
          text-align: center;
        }

      }
    }
  }
}
