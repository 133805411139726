.wrapper {
  padding: 60px 0;

  .bg-white {
    background: transparent;
  }

  h2 {
    font-family: Montserrat, sans-serif;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
    color: var(--text-grey2);

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  .line {
    width: 100%;
    max-width: 500px;
    height: 1px;
    background-color: var(--text-grey2);
    margin: 15px auto;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .videoContainer {
    display: flex;
    padding-top: 350px;
    padding-bottom: 100px;

    @media (max-width: 768px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
}
