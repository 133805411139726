.wrapper {

  .lowStockMessage {
    color: var(--text-green);
    text-align: center;
  }

  .title {
      font-family: Inter, sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: var(--text-brown);
      font-weight: 300;
      margin: 10px 0;
  }

  .items {

    display: flex;
    flex-direction: column;
    width: 100%;

    .item {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      background: var(--light-background);
      margin: 10px 0;
      padding: 0 25px;
      height: 55px;
      border-radius: 5px;
      cursor: pointer;
      transition: .15s;

      @media (max-width: 768px) {
        padding: 0 15px;
        height: 65px;
      }

      .recommend {
        position: absolute;
        font-family: Inter, sans-serif;
        font-size: 10px;
        background: var(--background-green);
        color: white;
        left: 25px;
        top: -12px;
        padding: 5px 10px;
        border-radius: 10px;
        letter-spacing: 2px;
        font-weight: 700;
      }

      .itemText {
        font-family: Inter, sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: var(--text-brown);
        font-weight: 300;

        @media (max-width: 768px) {
          font-size: 14px;
        }

        strong {
          font-weight: 700;
        }
      }

      .price {
        position: relative;
        font-weight: 700;
        font-size: 20px;
        color: var(--background-green);

        .regularPrice {
          font-weight: 500;
          font-size: 14px;
          margin-bottom: -10px;
          text-decoration: line-through;
          white-space: nowrap;
        }
      }


      &:hover,
      &.active {
        background: var(--background-green);
        transition: .15s;

        .itemText {
          color: var(--light-background);
        }

        .price {
          color: var(--light-background);

          .regularPrice {
            display: block;
          }
        }

        .recommend {
          display: none;
        }
      }

    }
  }

  .quantityContainer {

    display: flex;
    align-items: center;
    justify-content: space-between;

    .quantityControl {
      display: inline-flex;
      background: white;
      padding: 5px 10px;
      border: 1px solid var(--border-gray);

      input, button {
        color: var(--text-brown);
        font-size: 16px;
      }

      button {
        padding: 5px;
        transition: .15s;

        &:disabled {
          opacity: 0.4;
          transition: .15s;
        }
      }

      input {
        padding: 5px 10px;
        outline: none;
        text-align:center;
        width: 50px;
      }
    }

    .priceContainer {

      .price {
        text-align: right;
        font-size: 14px;
        color: var(--text-dark);
        font-weight: 500;
        text-decoration: line-through;
        margin-bottom: -5px;
      }

      .discountPrice {
        font-size: 20px;
        line-height: 25px;
        color: var(--text-green);
        font-weight: 700;
      }

    }
  }

  .subtotal {
    .productPrice,
    .discountPrice,
    .totalPrice {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 7px 0
    }

    .productPrice {
      label {
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        color: var(--text-gray);
        font-family: Inter, sans-serif;
      }

      .price {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        color: var(--text-dark);
        font-family: Inter, sans-serif;
      }
    }

    .discountPrice {
      label {
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        color: var(--text-gray);
        font-family: Inter, sans-serif;
      }

      .price {
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        color: var(--text-dark);
        font-family: Inter, sans-serif;
      }
    }

    .totalPrice {
      label {
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        color: var(--text-gray);
        font-family: Inter, sans-serif;
      }

      .price {
        font-size: 20px;
        line-height: 25px;
        font-weight: 700;
        color: var(--text-green);
        font-family: Inter, sans-serif;
      }
    }
  }

  .cartButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px auto;
    height: 40px;
    background: var(--background-green);
    color: var(--text-light);
    border-radius: 20px;
    border: none;
    transition: .15s;

    &:hover {
      opacity: .9;
      transition: .15s;
    }
  }
}
