.wrapper {
  padding: 60px 0;

  @media (max-width: 768px) {
    padding: 30px 0;
  }

  .subtitle {
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    color: var(--text-grey2);
  }
  h2 {
    font-family: Montserrat, sans-serif;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
    color: var(--text-grey2);

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  .line {
    width: 100%;
    max-width: 500px;
    height: 1px;
    background-color: var(--text-grey2);
    margin: 15px auto;

    @media (max-width: 768px) {
      max-width: 250px;
    }
  }

  .items {

    width: 70%;
    margin: 0 auto;
    margin-top: 80px;

    @media (max-width: 768px) {
      width: 100%;
      margin-top: 40px;
    }

    .item {

      cursor: pointer;
      border-bottom: 1px solid #5B4841;

      .titleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .arrowContainer {
          display: flex;
          align-items: center;
          width: 25px;
        }

        svg {
          width: 25px;
          transform: rotate(0deg);
          transition: .15s;
        }
      }

      h3 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: var(--text-dark);
        margin: 20px 0;
      }

      p {
        font-family: Inter, sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        color: var(--text-gray);
      }

      .content {
        height: 0;
        overflow: hidden;
        transition: .15s;
      }

      &.active {
        .content {
          padding-top: 10px;
          padding-bottom: 30px;

          height: unset;
          transition: .15s;
        }

        svg {
          transform: rotate(180deg);
          transition: .15s;
        }
      }
    }
  }
}
