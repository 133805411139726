.wrapper {
  width: 100%;

  .imageWrapper {

    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 0;
    padding-top: 100%;

    @media (max-width: 768px) {
      width: calc(100% - 40px);
      padding-top: calc(100% - 40px);
    }

    img {
      border-radius: 10px;
    }

    .buttonLeft,
    .buttonRight {
      position: absolute;
      top: calc(50% - 20px);
      z-index: 999;

      &:disabled {
        opacity: .5;
      }
    }

    .buttonRight {
      right: -30px;
    }

    .buttonLeft {
      left: -30px;
    }

  }

  .imageThumbContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    @media (max-width: 768px) {
      display: none;
    }

    .imageThumb {
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      transition: .15s;

      &.active {
        border-color: var(--border-brown);
        transition: .15s;
      }

      width: calc(33% - 5px);
      height: 0;
      padding-top: calc(33% - 10px);

      img {
        border-radius: 10px;
      }
    }
  }
}
