.wrapper {
  padding: 60px 0;

  background: white;
  border-radius: 30px;

  h2 {
    font-family: Montserrat, sans-serif;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
    color: var(--text-grey2);

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  .videoContainer {
    display: flex;
    padding-top: 400px;
    padding-bottom: 100px;

    @media (max-width: 768px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
}
