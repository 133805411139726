.wrapper {
  padding: 40px 0;

  .titleContainer {
    width: calc(100% - 120px);
    margin: 0 auto;
    background: white;
    padding: 60px;
    border-radius: 30px;

    @media (max-width: 768px) {
      padding: 30px;
      margin-top: -70px;
      z-index: 9999999;
      width: calc(100% - 60px);
    }

    h2 {
      text-align: center;
      font-family: Montserrat, sans-serif;
      font-weight: 700;
      line-height: 40px;
      font-size: 36px;
      letter-spacing: 4px;
      color: var(--text-grey2);
      text-transform: uppercase;

      @media (max-width: 768px) {
        line-height: 24px;
        font-size: 16px;
        letter-spacing: 2px;
      }
    }
  }

  .contentContainer {
    text-align: center;

    label {
      font-family: Inter, sans-serif;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      color: var(--text-grey2);
      text-align: center;
      margin: 25px 0;
      display: block;
    }

    p {
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-grey2);
    }
  }

}
