.wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 45px;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }

  h2 {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 32px;
    letter-spacing: 4px;
    text-align: center;
    margin: 45px 0;
    color: var(--text-grey2);
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .sliderContainer {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100%;

    .sliderItem {
      width: 10%;
      display: flex;
      align-items: center; /* Centers content vertically */
      justify-content: center;
      padding: 30px;
      padding-top: 25px;
      opacity: .5;
      transition: .15s;
      background: white;
      border-radius: 10px;
      scale: .7;

      @media (max-width: 768px) {
        width: calc(100% - 70px) !important;
        margin: 0 35px;
      }

      .text {
        font-family: Inter, sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: var(--text-grey2);
        margin-bottom: 20px;
      }

      .images {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        margin-bottom: 20px;

        .imageWrapper {
          position: relative;
          width: calc(50% - 5px);
          height: 0;
          padding-top: calc(50% - 5px);
          margin: 5px 0;

          label {
            font-family: Montserrat, sans-serif;
            position: absolute;
            color: white;
            left: 10px;
            bottom: 5px;
            z-index: 9999999;
            font-size: 16px;
            font-weight: 700;
          }

          img {
            z-index: 0;
          }

          @media (max-width: 768px) {
            width: 50%;
            padding-top: 50%;
            margin: 0;
          }
        }
      }

      &.active {
        opacity: 1;
        scale: 1;
        transition: .15s;
      }

      .imageWrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
      }

      .source {
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: var(--text-grey2);
        font-style: italic;
        text-align: center;margin-top: 20px;
      }
    }
  }
}
