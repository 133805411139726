.wrapper {

  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-bottom: 30px;
  }


  .tableWrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    .left {
      width: calc(100% - 400px);

      @media (max-width: 768px) {
        width: 100%;
      }

      .table {
        display: flex;
        flex-direction: column;

        .item {
          display: flex;
          flex-direction: row;
          width: 100%;

          .full {
            display: flex;
            width: 100%;
            height: 60px;
            color: var(--text-grey2);
            font-size: 16px;
            line-height: 24px;
            align-items: center;

            @media (max-width: 768px) {
              font-size: 12px;
              line-height: 18px;
            }
          }

          .value1 {
            display: flex;
            width: 50%;
          }

          .value2, .value3 {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .value1, .value2, .value3 {
            font-family: Montserrat, sans-serif;
            font-weight: 300;
            display: flex;
            align-items: center;
            color: var(--text-grey2);
            font-size: 16px;
            line-height: 24px;
            height: 60px;
            border-bottom: 1px solid var(--border-brown);

            @media (max-width: 768px) {
              font-size: 12px;
              line-height: 18px;
            }
          }

          .header {
            font-weight: 700;
          }
        }
      }
    }

    .right {
      position: relative;
      width: 380px;

      @media (max-width: 768px) {
        display: none;
      }
    }

  }
}
