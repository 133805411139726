.containerWrapper {

  background: transparent;

  @media (max-width: 768px) {
    background: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .wrapper {
    padding: 80px 0;
    background: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    @media (max-width: 768px) {
      padding: 60px 15px;
    }

    h2 {
      text-align: center;
      color: var(--text-grey2);
      font-weight: 700;
      line-height: 43px;
      font-size: 36px;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        line-height: 30px;
        font-size: 24px;
        letter-spacing: 2px;
      }
    }

    .content {
      display: flex;
      width: 100%;
      margin-top: 30px;
      justify-content: center;
      align-items: center;

      p {
        font-family: Inter, sans-serif;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        font-weight: 300;
        color: var(--text-dark);
      }
    }

    a {
      width: 100%;
      max-width: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 16px auto;
      height: 40px;
      background: var(--background-green);
      color: var(--text-light);
      border-radius: 20px;
      border: none;
      text-transform: uppercase;
      transition: .15s;

      @media (max-width: 768px) {
        max-width: 250px;
      }

      &:hover {
        opacity: .9;
        transition: .15s;
      }
    }
  }

}
