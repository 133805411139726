.wrapper {

  .content {

    width: 80%;
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 100%;
    }

    h2 {
      font-size: 36px;
      line-height: 43px;
      letter-spacing: 4px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      margin: 35px 0;
      color: var(--text-grey2);
      font-family: Montserrat, sans-serif;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    p {
      font-family: Inter, sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      font-weight: 300;
      color: var(--text-grey2);
      margin: 15px 0;
    }

    &.left {
      padding-bottom: 40px;
      width: 100%;

      @media (max-width: 768px) {
        padding-bottom: 30px;
      }

      h2 {
        text-align: left;
      }

      p {
        text-align: left;
      }
    }
  }

  .proConsWrapper {

    width: 90%;
    margin: 0 auto;

    .proConsContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 50px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .proConsItems {
        width: calc(50% - 15px);

        @media (max-width: 768px) {
          width: 100%;
        }

        .item {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: Inter, sans-serif;
          margin: 15px 0;
          color: var(--text-grey2);

          svg {
            margin-right: 15px;
            min-width: 20px;
          }
        }
      }
    }

    h2 {
      font-size: 36px;
      line-height: 43px;
      letter-spacing: 4px;
      text-transform: uppercase;
      font-weight: 400;
      text-align: center;
      margin: 55px 0;
      color: var(--text-grey2);
      font-family: Montserrat, sans-serif;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
      }

      strong {
        font-weight: 700;
      }
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
      color: var(--text-grey2);
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }



  }
}
